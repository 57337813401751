body {
  min-height: 100%;
  font-family: Roboto;
  background: #f1f1f1;
}

a {
  color: #333333;
}

hr {
  margin: 10px 14px;
}

.is-logo {
  float: left;
  margin-left: 18px;
}

.logo {
  margin-left: 15px;
  font-size: 30px;
  font-weight: bold;
  float: left;
}
.logo:hover {
  text-decoration: none;
}
.logo i {
  margin-left: 5px;
  color: #e91d00;
}

header {
  background: #ffffff;
  padding: 10px;
  border-bottom: 1px solid #e8e8e8;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
}
header .searchbox-container {
  margin-left: 240px;
  max-width: 400px;
}
header .searchbox-container .root {
  max-width: inherit;
}
header .searchbox-container .input {
  padding-left: 14px;
}
header .searchbox-container .form {
  display: flex;
  margin-top: 5px;
}
header .searchbox-container .form .form-control {
  border-radius: 0;
  padding-bottom: 7px;
}
header .searchbox-container .form .form-control:focus {
  outline: none;
  box-shadow: none;
}
header .searchbox-container .form button {
  padding-left: 20px;
  padding-right: 20px;
  background: #f8f8f8;
  border-radius: 0;
  margin-left: -1px;
}

section {
  background: #f1f1f1;
  min-height: 100%;
  z-index: 1;
}
section aside {
  position: fixed;
  top: 70px;
  left: 0;
  bottom: 10px;
  width: 230px;
  background: #ffffff;
  border-right: 1px solid #e8e8e8;
}
section aside .nav {
  margin: 0 20px;
  font-size: 1.1em;
}
section aside .nav #genres li a {
  display: block;
  padding: 2px 10px;
  margin: 10px 0;
}
section aside .nav #genres li a:hover {
  color: #ffffff;
  background: #333333;
}
section aside .nav li.separator {
  height: 1px;
  background: #e8e8e8;
}
section aside .panel-header {
  color: #ce1312;
  margin-left: 30px;
  text-transform: uppercase;
  font-size: 10px;
  margin-top: 20px;
  border-bottom: none;
}
section aside .badge {
  background: #bbbbbb;
  position: relative;
  top: 1px;
}
section aside #genres label {
  display: block;
  cursor: pointer;
  padding: 4px 4px 4px 8px;
  font-weight: normal;
  font-size: 0.9em;
  margin: 0 0 -1px;
}
section aside #genres label:hover {
  color: #ffffff;
  background: #333333;
}
section aside #genres label input {
  display: none;
}
section aside #genres .active label {
  border: 1px solid #ce1312;
  margin-top: -1px;
}
section aside #genres .active .badge {
  background: #333333;
}
section aside #ratings {
  margin-left: 10px;
}
section aside #ratings .link {
  font-size: 0.9em;
}
section aside #ratings .link:hover,
section aside #ratings .link:active,
section aside #ratings .link:focus {
  text-decoration: none;
}
section aside #ratings .item {
  margin-bottom: 6px;
}
section aside #ratings .selectedItem a {
  color: #ce1312;
}
section aside #ratings .starIcon {
  height: 14px;
  width: auto;
}
section article {
  margin-top: 70px;
  margin-bottom: 10px;
  margin-left: 237px;
  margin-right: 10px;
  padding: 10px 0;
  max-width: 100%;
  background: #ffffff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}
section article #stats {
  padding-right: 14px;
  font-size: 0.8em;
  line-height: 24px;
}
section article #hits {
  padding: 0 15px;
}
section article #hits .list {
  list-style: none;
  padding: 0;
}
section article #pagination .root {
  display: flex;
  justify-content: center;
}
section article #pagination .disabledItem {
  display: none;
}
section article #pagination .pagination a {
  background: #f8f8f8;
  padding: 3px 8px;
  color: #333333;
  margin-right: 4px;
}
section article #pagination .pagination a.selectedItem {
  border-color: #b5b5b5;
  background: #e8e8e8;
}
section article #pagination .pagination a:hover {
  border-color: #cfcfcf;
  background: #ebebeb;
}
section article .hit {
  margin-bottom: 10px;
  height: 130px;
  border: 1px solid #f3f3f3;
}
section article .hit mark {
  font-style: normal;
  background: #ffffd4;
  text-decoration: underline;
}
section article .hit .media-object {
  height: 130px;
  width: 130px;
  overflow: hidden;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}
section article .hit .media-heading {
  color: #167ac6;
  font-weight: normal;
  font-size: 18px;
}
section .thank-you {
  font-size: 0.8em;
  margin-top: 18px;
  margin-left: 30px;
}
section .thank-you a {
  color: #ce1312;
}

.genre,
.year {
  margin: 12px 0;
}

.year {
  font-weight: bold;
}

.genre .badge {
  background: #bbbbbb;
}

.starIcon {
  fill: #fbae00;
}
